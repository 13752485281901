import "./styles.css";
import exampleImageUrl from "/brothers.jpg";
import { saveAs } from "file-saver";

let startImage = new Image();
let name = "image";
let aspectRatio = 1;
startImage.crossOrigin = "anonymous";
startImage.src = exampleImageUrl;
let container = document.getElementById("container");
let modes = document.getElementById("modes");

let canvas = document.getElementById("canvas");
let ctx = canvas.getContext("2d");
let canvas2 = document.getElementById("overlayCanvas");
let ctx2 = canvas2.getContext("2d");
let canvas3 = document.getElementById("outputCanvas");
let ctx3 = canvas3.getContext("2d");
let canvas4 = document.getElementById("previewCanvas");
let ctx4 = canvas4.getContext("2d");

let dl = document.getElementById("download");
let cp = document.getElementById("copy");
dl.addEventListener("click", () => {
  canvas3.toBlob(function (blob) {
    saveAs(blob, name + ".png");
  });
});
cp.addEventListener("click", () => {
  canvas3.toBlob((blob) =>
    navigator.clipboard.write([new ClipboardItem({ "image/png": blob })])
  );
});
let cursorSize = canvas4.height / 10;
let lastCursorLoc = { x: 0, y: 0 };
function distance(aX, aY, bX, bY) {
  return Math.sqrt(Math.pow(aX - bX, 2) + Math.pow(aY - bY, 2));
}
function pointsAlongLine(startx, starty, endx, endy, spacing) {
  let dist = distance(startx, starty, endx, endy);
  let steps = dist / spacing;

  let points = [];
  for (var d = 0; d <= 1; d += 1 / steps) {
    let point = {
      x: startx * d + endx * (1 - d),
      y: starty * d + endy * (1 - d)
    };
    points.push(point);
  }
  return points;
}

function setup() {
  aspectRatio = startImage.width / startImage.height;
  let targetRatio = window.innerWidth / (window.innerHeight - 45);
  if (targetRatio < aspectRatio) {
    container.style.width = `100%`;
    let bounds = container.getBoundingClientRect();
    container.style.height = `${bounds.width / aspectRatio}px`;
  } else {
    container.style.height = `calc(100vh - 45px)`;
    window.setTimeout(() => {
      let bounds = container.getBoundingClientRect();
      window.document.body.style.width = `${bounds.height * aspectRatio}px`;
      container.style.width = `${bounds.height * aspectRatio}px`;
    }, 10);
  }

  canvas.width = startImage.width;
  canvas.height = startImage.height;
  canvas2.width = startImage.width;
  canvas2.height = startImage.height;
  canvas3.width = startImage.width;
  canvas3.height = startImage.height;
  canvas4.width = startImage.width;
  canvas4.height = startImage.height;
  cursorSize = canvas4.width / 40;
  // ctx.globalAlpha = 0.4;
  ctx.globalCompositeOperation = "source-over";
  ctx.fillStyle = "rgba(0,0,0,0.7)";

  ctx.fillRect(0, 0, canvas.width, canvas.height);
  let r = 10;
  for (let i = 0; i < canvas.width / r; i += 1) {
    for (let j = 0; j < canvas.height / r; j += 1) {
      ctx.fillStyle = "rgba(0,0,0,0.9)";
      if ((i + j) % 2 === 0) {
        ctx.fillRect(i * r, j * r, r, r);
      }
    }
  }
  ctx.globalCompositeOperation = "source-in";
  ctx.globalAlpha = 0.4;

  ctx.drawImage(startImage, 0, 0);
}
startImage.onload = () => {
  setup();
  ctx.globalCompositeOperation = "source-over";
  let h = canvas.height / 7;
  ctx.font = `${h}px Sans-Serif`;
  ctx.fillText("Paint to select area", 10, h + 10, canvas.width - 20);
};

function render() {
  ctx3.clearRect(0, 0, startImage.width, startImage.height);

  ctx3.globalCompositeOperation = "source-over";

  ctx3.drawImage(startImage, 0, 0);
  ctx3.globalCompositeOperation = "destination-in";
  ctx3.drawImage(canvas2, 0, 0);
}
function paint(x, y) {
  if ((mousedown === 3) ^ erasing) {
    ctx2.globalCompositeOperation = "destination-out";
  } else {
    ctx2.globalCompositeOperation = "source-over";
  }

  ctx2.beginPath();
  ctx2.arc(x, y, cursorSize, 0, 2 * Math.PI);
  ctx2.fill();
}
function renderStroke(x, y) {
  if (!mousedown) {
    return;
  }

  let points = pointsAlongLine(
    x,
    y,
    lastCursorLoc.x,
    lastCursorLoc.y,
    cursorSize / 2
  );
  points.forEach((p) => paint(p.x, p.y));
  render();
}

function renderCursor() {
  ctx4.clearRect(0, 0, canvas4.width, canvas4.height);

  ctx4.globalCompositeOperation = "source-over";

  ctx4.beginPath();
  ctx4.arc(lastCursorLoc.x, lastCursorLoc.y, cursorSize, 0, 2 * Math.PI);
  ctx4.fill();

  ctx4.globalCompositeOperation = "source-in";

  ctx4.drawImage(startImage, 0, 0);
  ctx4.lineWidth = 0.5;
  ctx4.globalCompositeOperation = "source-over";

  ctx4.stroke();
}
let mousedown = false;
let erasing = false;
canvas3.addEventListener("mousedown", (e) => {
  mousedown = e.button + 1;
  modes.className = e.button === 2 ? "flip" : "";
  let bounds = canvas3.getBoundingClientRect();
  let x = e.offsetX * (canvas3.width / bounds.width);
  let y = e.offsetY * (canvas3.height / bounds.height);
  paint(x, y);
  render();
});
canvas3.addEventListener("contextmenu", (e) => {
  e.preventDefault();
});

canvas3.addEventListener("mousemove", (e) => {
  canvas.className = "";

  let bounds = canvas3.getBoundingClientRect();
  let x = e.offsetX * (canvas3.width / bounds.width);
  let y = e.offsetY * (canvas3.height / bounds.height);

  renderStroke(x, y);
  lastCursorLoc = {
    x,
    y
  };
  renderCursor();
});

canvas3.addEventListener("touchstart", (e) => {
  mousedown = true;
  canvas.className = "";

  let touches = Array.from(e.touches);
  if (touches.length !== 1) {
    return;
  }
  let touch = touches[0];
  let bounds = canvas3.getBoundingClientRect();
  let x = (touch.clientX - bounds.left) * (canvas3.width / bounds.width);
  let y = (touch.clientY - bounds.top) * (canvas3.height / bounds.height);
  paint(x, y);
  render();

  lastCursorLoc = {
    x,
    y
  };
});
canvas3.addEventListener("touchmove", (e) => {
  // e.preventDefault;
  mousedown = true;
  let touches = Array.from(e.touches);
  if (touches.length !== 1) {
    return;
  }
  e.preventDefault();
  let touch = touches[0];
  let bounds = canvas3.getBoundingClientRect();
  let x = (touch.clientX - bounds.left) * (canvas3.width / bounds.width);
  let y = (touch.clientY - bounds.top) * (canvas3.height / bounds.height);
  renderStroke(x, y);
  lastCursorLoc = {
    x,
    y
  };
  mousedown = false;
});

window.addEventListener("mouseup", (e) => {
  mousedown = false;
  modes.className = "";
});
canvas3.addEventListener("mouseout", (e) => {
  canvas.className = "fade";
});
canvas3.addEventListener("wheel", (e) => {
  cursorSize += e.deltaY * 0.01;
  cursorSize = Math.max(cursorSize, 2);
  renderCursor();
});
document
  .getElementById("fileInput")
  .addEventListener("change", handleFiles, false);

function handleFiles(e) {
  let files = e.target.files;
  if (files.length === 0) {
    return;
  }
  let file = e.target.files[0];
  name = file.name.replace(/\.[^/.]+$/, "");

  let reader = new FileReader();
  reader.onload = function (event) {
    let img = new Image();
    img.onload = function () {
      startImage = img;
      setup();
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
}

let small = document.getElementById("small");

let medium = document.getElementById("medium");
let big = document.getElementById("big");

function clearSelected() {
  small.classList.remove("selected");
  medium.classList.remove("selected");
  big.classList.remove("selected");
}
function clearToolSelected() {
  add.classList.remove("selected");
  erase.classList.remove("selected");
}
small.addEventListener("click", () => {
  cursorSize = canvas4.height / 90;
  clearSelected();
  small.classList.add("selected");
});
medium.addEventListener("click", () => {
  cursorSize = canvas4.height / 40;
  clearSelected();
  medium.classList.add("selected");
});
big.addEventListener("click", () => {
  cursorSize = canvas4.height / 18;
  clearSelected();
  big.classList.add("selected");
});
let add = document.getElementById("add");
let erase = document.getElementById("erase");
add.addEventListener("click", () => {
  erasing = false;

  clearToolSelected();
  add.classList.add("selected");
});
erase.addEventListener("click", () => {
  erasing = true;
  clearToolSelected();
  erase.classList.add("selected");
});
